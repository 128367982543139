import Typo from 'typo-js';
import Spellchecker from 'hunspell-spellchecker';
import raw from "raw.macro";

/**
 * singleton class for loading dictionnary online one time
 *
 * @author Marc Ben Fatma
 */
export default class Dictionnary {
  static typo = null;

  /**
   * creating Typo instance
   */
  static createTypo() {
      /*const dictionnary= new Typo(
        'lb_LU',
        false,
        false, { dictionaryPath: "./dictionaries" });*/
        const affFile = raw("./dictionaries/lb_LU/lb_LU.aff");
        const dicFile = raw("./dictionaries/lb_LU/lb_LU.dic");
        const dictionnary = new Spellchecker();
        const DICT = dictionnary.parse({
            aff: affFile,
            dic: dicFile
        });
        dictionnary.use(DICT);
        return dictionnary;
  }

  /**
   * getting Typo instance
   */
  static getTypo () {
      if (!Dictionnary.typo) { console.log('create typo');
          Dictionnary.typo = Dictionnary.createTypo();
      }
      return Dictionnary.typo;
  }
}
