import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Dictionnary from './Dictionnary';

/**
 * TinyMCE component with spellchecker
 *
 * @author Marc Ben Fatma
 */
export default class TextEditor extends React.Component {

  constructor(props) {
        super(props);
        this.tinyMCE=null;
  }

  /**
   * lauching the spellcheck command for the editor
   */
  onSpellCheck() {
    window.tinymce.activeEditor.execCommand('mceSpellCheck');
  }

  /**
   * render the component
   */
  render() {
    return (
    <>
    <Editor
      ref = { (el)=>{ this.tinyMCE=el; } }
      apiKey="v0wwbekcca4cwq0bc8hv3894z36jl1gf86exabiqv06j6d9l"
      // onEditorChange={() => this.onSpellCheck()}
      init={{
              width: '100%',
              height: 400,
              menubar: false,
              browser_spellcheck: false,
              plugins: ['spellchecker'],
              toolbar: 'undo redo | spellchecker',
              content_style: ".mce-spellchecker-word { border-bottom: 2px solid #F00 !important; cursor: default; }",
              spellchecker_language: 'LU',
              spellchecker_languages: 'Luxembourgish=LU',
              spellchecker_wordchar_pattern: /[^\s!"#$%&()*+,-./:;<=>?@[\]^_{|}§©«®±¶·¸»¼½¾¿×÷¤\u201d\u201c\'’´`„]+/g,
              spellchecker_callback: function (method, text, success, failure, dictionnary) {
                let words = text.match(this.getWordCharPattern());
                // spellchecking by associating each word sugestions if needed
                if (method === "spellcheck") {
                  let suggestions = {};
                  let sugestion = {};
                  for (let i = 0; i < words.length; i++) {
                    let word = words[i];
                    if(! isNaN(word)){
                        continue;
                    }
                    sugestion = Dictionnary.getTypo().suggest(word);
                    if (sugestion && sugestion.length > 0) {
                      suggestions[words[i]] = sugestion;
                    }
                  }
                  success({ words: suggestions, dictionary: [ ] });
                } else if (method === "addToDictionary") {
                  // Add word to dictionary here
                  success();
                }
              }
            }}
      initialValue=""></Editor>
      <p className="actionrow">
        <button onClick={() => this.onSpellCheck()}>Verbesseren</button>
      </p>
      </>
  )}
}
