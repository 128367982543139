import './App.css';
import TextEditor from './components/TextEditor';
import Dictionnary from './components/Dictionnary';

/**
 * application function
 *
 * @author Marc Ben Fatma
 */
function App() {
  // preloading dictionnary on app display
  Dictionnary.getTypo();

  return (
    <div className="App">
      <header className="App-header">
        <TextEditor />
      </header>
    </div>
  );
}

export default App;
